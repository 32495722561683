<template>
  <div class="about">
    <Navbar/>  
    <div class="container main-div">
        <div class="row">
          
          <div class="col-12 col-lg-6 offset-lg-3">
            <div class="h1-wrapper">
                <h1>О компании Institut Hugo</h1>
            </div>
            <div>
              <p>
              Мы живем в Париже, поэтому знаем нюансы французской жизни изнутри. Выпускница топ-3 бизнес-школ Франции с опытом работы в крупнейших мировых компаниях и владелец успешного бизнеса во Франции, мы поможем вам правильно сориентироваться в потоке информации и принять правильное решение. 
              </p>
            </div>
            <div class="cta-wrapper">
              <a class="btn btn-light btn-insta" href="https://instagram.com/tani.paris">
                <img class="user-icon" src='@/assets/instagram-white.svg' alt=""> Наш Instagram - 21k 
              </a>
            </div>
            
          </div>
        </div>

    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'

export default {
  name: 'BusinessSchools',
  components: {
    Navbar
  }
}
</script>
